import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SkfLoaderTilesComponent } from "src/app/Projects/Shared/Layout/skf-loader-tiles/skf-loader-tiles.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MaterialManagerModule } from "src/app/Projects/Shared/Layout/material-manager/material-manager.module";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
// import { TooltipModule } from "ngx-tooltip";
import { SkfUiModule } from "src/app/Projects/Shared/skf-ui/skf-ui.module";
import { NgToggleModule } from "@nth-cloud/ng-toggle";
import { SidebarComponent } from "src/app/Projects/Shared/Layout/sidebar/sidebar.component";
import { WindowTitleComponent } from "src/app/Projects/Shared/Layout/window-title/window-title.component";
import { BreadcrumbsComponent } from "src/app/Projects/Shared/Layout/breadcrumbs/breadcrumbs.component";
import { TitleComponent } from "src/app/Projects/Shared/Layout/title/title.component";
import { ModalComponent } from "./modal/modal.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { RouterModule } from "@angular/router";
import { CustomHeaderComponent } from "./Layout/custom-header/custom-header.component";
import { CompassCardComponent } from "./Layout/compass-card/compass-card.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { CustomDataTableComponent } from "./Layout/custom-data-table/custom-data-table.component";
import {
  SKFUICardModule,
  SKFUICommonModule,
  SKFUIInputModule,
  SKFUIDataTableModule,
  SKFUIAutocompleteModule,
} from "@skf/ui";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CompassModalComponent } from "./Layout/compass-modal/compass-modal.component";
import { MatTableModule} from "@angular/material/table";
import { MatSelectModule} from "@angular/material/select";
import { MatFormFieldModule} from "@angular/material/form-field";
import { MatSortModule} from "@angular/material/sort";
import { MatRadioModule} from "@angular/material/radio";
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
// import { AngularImageViewerModule } from "@hreimer/angular-image-viewer";

// import { ImageViewerModule } from 'ng2-image-viewer';
import { AngularImageViewerModule } from "angular-x-image-viewer";
@NgModule({
    declarations: [
        SkfLoaderTilesComponent,
        SidebarComponent,
        WindowTitleComponent,
        TitleComponent,
        BreadcrumbsComponent,
        CustomHeaderComponent,
        CompassCardComponent,
        CustomDataTableComponent,
        CompassModalComponent,
        ModalComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        MaterialManagerModule,
        NgbModule,
        // TooltipModule,
        SkfUiModule,
        NgToggleModule,
        FlexLayoutModule,
        RouterModule,
        TranslateModule.forRoot({
            defaultLanguage: "en",
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
                deps: [HttpClient],
            },
        }),
        DragDropModule,
        SKFUICardModule,
        SKFUIInputModule,
        SKFUICommonModule,
        SKFUIDataTableModule,
        SKFUIAutocompleteModule,
        MatTableModule,
        MatSelectModule,
        MatFormFieldModule,
        MatSortModule,
        MatRadioModule,
        PdfViewerModule,
        NgxDocViewerModule,
        // AngularImageViewerModule
        // TranslateModule.forChild({extend: true}),
        AngularImageViewerModule
    ],
    exports: [
        SkfLoaderTilesComponent,
        NgxDatatableModule,
        MaterialManagerModule,
        NgbModule,
        // TooltipModule,
        SkfUiModule,
        NgToggleModule,
        SidebarComponent,
        WindowTitleComponent,
        TitleComponent,
        BreadcrumbsComponent,
        FlexLayoutModule,
        CustomHeaderComponent,
        CompassCardComponent,
        CustomDataTableComponent,
        CompassModalComponent,
        TranslateModule,
        ModalComponent,
    ],
    providers: [NgbActiveModal]
})
export class SharedModule {}
